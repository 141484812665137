<template>
  <div class="flex align-items justify-content-center">
    <el-upload
        class="avatar-uploader"
        :action="action"
        accept="image/jpeg,image/png"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
      <el-image v-if="url" :src="url" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    label: {
      type: String,
      default: ""
    },
    index: {
      type: Number,
      default: 0
    },
    url: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      action: this.actionUrl
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      // const resultImg = URL.createObjectURL(file.raw);
      if(res.code === 200){
        const resultImg = res.data.path;
        this.$emit('upload', {
          image: resultImg,
          label: this.label,
          index: this.index
        });
      }else{
        this.$message.error(res.msg)
      }
    },
    beforeAvatarUpload(file) {
      const isImg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
      const isLt2M = file.size / 1024 / 1024 < 20;

      if (!isImg) {
        this.$message.error('上传图片格式错误!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!');
      }
      return isImg && isLt2M;
    }
  }
}
</script>

<style scoped>
.avatar-uploader{
  width: 40px;
  height: 40px;
  font-size: 0;
}
.avatar-uploader /deep/.el-upload {
  width: 40px;
  height: 40px;
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.avatar {
  display: block;
  width: 40px;
  height: 40px;
}
</style>
<template>
  <div>
    <avue-form ref="form" v-model="form" :option="formOption">
      <template slot="content">
        <!-- <avue-ueditor v-model="form.content"
                      :options="options"></avue-ueditor> -->
        <FormEditor
          :details="form.content"
          @editoChange="editoChange"
          :disabled="type == 1"
        />
      </template>
    </avue-form>
  </div>
</template>

<script>
import FormEditor from "@/components/formEditor/index";
export default {
  name: "createThree",
  components: {
    FormEditor,
  },
  props:{
    type:Number
  },
  data() {
    return {
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "商品详情",
            prop: "content",
            type: "textarea",
            span: 24,
            row: true,
            rules: [
              {
                required: false,
                message: "请输入商品详情",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      options: {
        action: this.actionUrl,
        customConfig: {}, //wangEditor编辑的配置
        headers: {
          Authorization: this.$store.state.user.Token,
        },
        props: {
          url: "path",
          res: "data",
          name: "path",
        },
      },
    };
  },
  methods: {
    async setForm(form) {
      this.form = form;
    },
    editoChange(e) {
      this.form.content = e
    },
    submitForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid, done) => {
          if (valid) {
            done();
            resolve(this.form);
          } else {
            done();
            resolve(false);
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ this.id ? "编辑商品" : "新增商品" }}</h3>
    <el-tabs v-model="activeName" :before-leave="beforeLeave">
      <el-tab-pane label="基本信息" name="first">
        <createOne :type="type" ref="createOne" @onload="onload" />
        <div class="flex align-items justify-content-center marginTop20">
          <el-button size="medium" type="primary" @click="nextStep('first')"
            >下一步</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="规格信息" name="second">
        <createTwo :type="type" ref="createTwo" />
        <div class="flex align-items justify-content-center marginTop20">
          <el-button size="medium" @click="previousStep('first')"
            >上一步</el-button
          >
          <el-button size="medium" type="primary" @click="nextStep('second')"
            >下一步</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="商品详情" name="third">
        <createThree :type="type" ref="createThree" />
        <div class="flex align-items justify-content-center">
          <el-button size="medium" @click="previousStep('second')"
            >上一步</el-button
          >
          <el-button
            size="medium"
            :disabled="submitType"
            type="primary"
            @click="handleSubmit"
            v-if="type == 2"
            >提交</el-button
          >
          <el-button size="medium" @click="handleBack">取消</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import createOne from "./components/createOne.vue";
import createTwo from "./components/createTwo.vue";
import createThree from "./components/createThree.vue";
import { add, update, view } from "@/api/supplierGoods/goods";
export default {
  name: "info",
  components: {
    createOne,
    createTwo,
    createThree,
  },
  data() {
    return {
      id: this.$route.query.id,
      type: this.$route.query.type,
      activeName: "first",
      formOne: {},
      formTwo: {},
      formThree: {},
      submitType: false, // 避免重复点击
    };
  },
  mounted() {},
  methods: {
    async onload() {
      if (this.id) {
        await this.getView();
      }
    },
    previousStep(e) {
      this.activeName = e;
    },
    async nextStep(stop) {
      if (stop === "first") {
        const dataOne = await this.$refs.createOne.submitForm();
        return new Promise((resolve, reject) => {
          if (dataOne) {
            console.log("dataOne", dataOne);
            this.$refs.createTwo.listSpecId(dataOne.supplierId);
            const { classifyId, ...rest } = dataOne;
            let classifyOneId = "";
            let categoryId = "";
            let classifyThreeId = "";
            if (classifyId.length === 1) {
              classifyOneId = classifyId[0];
            } else if (classifyId.length === 2) {
              classifyOneId = classifyId[0];
              categoryId = classifyId[1];
            } else if (classifyId.length === 3) {
              classifyOneId = classifyId[0];
              categoryId = classifyId[1];
              classifyThreeId = classifyId[2];
            }
            this.formOne = {
              classifyOneId,
              classifyThreeId,
              categoryId,
              ...rest,
            };
            this.activeName = "second";
            // console.log('this.formOne',this.formOne);
            resolve();
          } else {
            reject();
          }
        });
      }
      if (stop === "second") {
        const dataTwo = await this.$refs.createTwo.submitForm();
        return new Promise((resolve, reject) => {
          if (dataTwo) {
            const { specsData, specsList } = dataTwo;

            console.log(dataTwo,'dataTwodataTwodataTwo')
            const spec = specsList.map((item) => {
              return {
                id: item.id,
                childIds: item.checkList,
              };
            });
            this.formTwo = {
              specDetail: specsData,
              spec,
            };
            this.activeName = "third";
            // console.log('this.formTwo',this.formTwo);
            resolve();
          } else {
            reject();
          }
        });
      }
    },
    async beforeLeave(route, stop) {
      // console.log(route, stop);
      if (stop === "first") {
        const dataOne = await this.$refs.createOne.submitForm();
        if(dataOne.classifyId.length === 1) return this.$message.error('商品分类至少选择二级')
        return new Promise((resolve, reject) => {
          if (dataOne) {
            // console.log('dataOne',dataOne);
            this.$refs.createTwo.listSpecId(dataOne.supplierId);
            const { classifyId, ...rest } = dataOne;
            let classifyOneId = "";
            let categoryId = "";
            let classifyThreeId = "";
            if (classifyId.length === 1) {
              classifyOneId = classifyId[0];
            } else if (classifyId.length === 2) {
              classifyOneId = classifyId[0];
              categoryId = classifyId[1];
            } else if (classifyId.length === 3) {
              classifyOneId = classifyId[0];
              categoryId = classifyId[1];
              classifyThreeId = classifyId[2];
            }
            this.formOne = {
              classifyOneId,
              categoryId,
              classifyThreeId,
              ...rest,
            };
            // console.log('this.formOne',this.formOne);
            resolve();
          } else {
            reject();
          }
        });
      } else if (stop === "second") {
        if (route === "first") {
          const dataOne = await this.$refs.createOne.submitForm();
          return new Promise((resolve, reject) => {
            if (dataOne) {
              resolve();
            } else {
              reject();
            }
          });
        } else {
          const dataTwo = await this.$refs.createTwo.submitForm();
          return new Promise((resolve, reject) => {
            if (dataTwo) {
              const { specsData, specsList } = dataTwo;
              const spec = specsList.map((item) => {
                return {
                  id: item.id,
                  childIds: item.checkList,
                };
              });
              this.formTwo = {
                specDetail: specsData,
                spec,
              };
              // console.log('this.formTwo',this.formTwo);
              resolve();
            } else {
              reject();
            }
          });
        }
      } else if (stop === "third") {
        // const dataThree = await this.$refs.createThree.submitForm();
        // console.log('dataThree',dataThree);
        const three = true;
        return new Promise((resolve, reject) => {
          if (three) {
            // this.formThree = dataThree;
            // console.log('this.formThree',this.formThree);
            resolve();
          } else {
            reject();
          }
        });
      }
    },
    async handleSubmit() {
      const dataThree = await this.$refs.createThree.submitForm();
      if (dataThree) {
        this.formThree = dataThree;
        // console.log('this.formThree',this.formThree);
        // console.log(this.id)
        this.submitType = true;
        let one = { ...this.formOne };
        one.bannerImg = one.bannerImg.join(",");
        one.logoImg = one.logoImg.join(",");
        one.mainImg = one.mainImg.join(",");
        one.video = one.video ? one.video.join(','):''
        
        let list = one.classifyIds.map(element => {
          return element[2]
        });
        one.classifyIds =  list.join(',')
        if (this.id) {
          update({
            id: this.id,
            ...one,
            ...this.formTwo,
            ...this.formThree,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("更新成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              this.submitType = false;
            }
          }).catch(()=>{
            this.submitType = false;
          });
        } else {
          add({
            ...one,
            ...this.formTwo,
            ...this.formThree,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success("添加成功");
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
              this.submitType = false;
            }
          }).catch(()=>{
            this.submitType = false;
          });
        }
      }
    },
    handleBack() {
      this.$router.go(-1);
    },
    async getView() {
      const { data } = await view(this.id);
      // console.log('data',data);
      const {
        secondCategoryId,
        firstCategoryId,
        thirdCategoryId,
        ...rest
      } = data;
      const specsIds = data.spec.length > 0 ? data.spec.map((item) => {
        return item.id;
      }):[]

      await this.$refs.createTwo.listSpecId();
      const formTwo = {
        specsIds,
        specsData: data.specDetail,
        spec: data.spec,
      };
      this.formTwo = {
        spec: data.spec,
        specDetail: data.specDetail,
      };
      await this.$refs.createTwo.setForm(formTwo);
      const formThree = {
        content: data.content,
      };
      this.formThree = {
        content: data.content,
      };
      await this.$refs.createThree.setForm(formThree);
      const formOne = {
        outerGoodsCode:data.outerGoodsCode,
        name: data.name, //商品名称
        brandId: data.brandId, //商品品牌
        classifyId: [firstCategoryId,secondCategoryId],
        classifyIds:data.classifyIdList,
        sort: data.sort, //商品排序
        mainImg:data.mainImg ? data.mainImg.split(","):[], //商品主图
        isPreSale: data.isPreSale, //商品预售
        sendDays:data.sendDays,
        logoImg: data.logoImg ? data.logoImg.split(","):[], //商品logo
        video: data.video ?data.video.split(","):[], //视频
        introduction: data.introduction, //商品简介
        freightType: data.freightType, // 运费设置:
        freight: data.freight, //运费
        unit: data.unit, //商品单位:
        sendIntroduction: data.sendIntroduction, //发货说明
        packageList: data.packageList, //包装清单
        bannerImg: data.bannerImg.split(","),
        status:data.status,
        templateId:data.templateId
      };
      let list = data.classifyIdList.map(element => {
          return element[2]
        });
      this.formOne = {
        categoryId: data.thirdCategoryId,
        name: data.name, //商品名称
        classifyIds:list.join(','),
        brandId: data.brandId, //商品品牌
        sort: data.sort, //商品排序
        sendDays:data.sendDays,
        mainImg: data.mainImg ? data.mainImg.split(","):[], //商品主图
        isPreSale: data.isPreSale, //商品预售
        logoImg: data.logoImg ? data.logoImg.split(","):[], //商品logo
        video: data.video ? data.video.split(","):[], //视频
        introduction: data.introduction, //商品简介
        freightType: data.freightType, // 运费设置:
        freight: data.freight, //运费
        code: data.code, //商品编码
        unit: data.unit, //商品单位:
        sendIntroduction: data.sendIntroduction, //发货说明
        packageList: data.packageList, //包装清单
        bannerImg: data.bannerImg.split(","),
        outerGoodsCode:data.outerGoodsCode,
        status:data.status
      };
      this.$refs.createOne.setForm(formOne);
    },
  },
};
</script>

<style scoped></style>

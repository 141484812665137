<template>
  <div>
    <avue-form
      ref="form"
      v-model="form"
      :option="formOption"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
      :upload-before="uploadBefore"
    >
      <!-- 商品主图 -->
      <template slot="mainImg">
        <FormImgUpload
          :url="form.mainImg"
          @upload="upload"
          :limit="1"
          :maxSize="5"
          :disabled="type == 1"
          accept="image/jpeg,image/png,image/jpg"
          :multiple="true"
        />
        <div class="tipImg">尺寸推荐:750x750，格式：jpeg,png,jpg</div>
      </template>
      <!-- 商品Logo -->
      <template slot="logoImg">
        <FormImgUpload
          :url="form.logoImg"
          @upload="upload1"
          :disabled="type == 1"
          :limit="1"
          :maxSize="5"
          accept="image/jpeg,image/png,image/jpg"
          :multiple="true"
        />
        <div class="tipImg">尺寸推荐:750x750，格式：jpeg,png,jpg</div>
      </template>
      <!-- 商品Logo -->
      <template slot="video">
        <FormImgUpload
          :url="form.video"
          @upload="upload3"
          :disabled="type == 1"
          :limit="1"
          :maxSize="5"
          accept="video/mp4,video/MP4"
          :multiple="true"
        />
        <div class="tipImg">格式：mp4,MP4</div>
      </template>
      <!-- 商品banner -->
      <template slot="bannerImg">
        <FormImgUpload
          :url="form.bannerImg"
          @upload="upload2"
          :disabled="type == 1"
          :limit="9"
          :maxSize="5"
          accept="image/jpeg,image/png,image/jpg"
          :multiple="true"
        />
        <div class="tipImg">尺寸推荐:750x750，格式：jpeg,png,jpg</div>
      </template>
    </avue-form>
  </div>
</template>

<script>
// import {listClassify, listSupplier} from "@/api/shop/goods";
import { getBrandList, listClassify,getTemplateList } from "@/api/supplierGoods/goods";
import { list } from "@/api/supplierGoods/grouping";
import {
  goodBrandTotalListAll,
  getGoodsUnitListAll
} from "@/api/goods/goods";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "createOne",
  components: {
    FormImgUpload,
  },
  props:{
    type:Number
  },
  data() {
    return {
      form: {
        mainImg: [],
        bannerImg: [],
        logoImg: [],
        video: [],
        freightType:3
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        column: [
          {
            label: "商品名称",
            prop: "name",
            maxlength: 100,
            showWordLimit: true,
            span: 12,
            row: true,
            disabled:this.type == 1,
            rules: [
              {
                required: true,
                message: "请输入商品名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "商品品牌",
            prop: "brandId",
            type: "select",
            disabled:this.type == 1,
            dicData: [],
            props: {
              label: "name",
              value: "id",
            },
            span: 12,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择商品品牌",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择商品品牌"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "商品分类",
            prop: "classifyId",
            type: "cascader",
            disabled:this.type == 1,
            span: 12,
            row: true,
            dicData: [],
            props: {
              label: "name",
              value: "id",
            },
            rules: [
              {
                required: true,
                message: "请选择商品分类",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择分类"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "商品分组",
            prop: "classifyIds",
            type: "cascader",
            disabled:this.type == 1,
            multiple: true,
            span: 12,
            row: true,
            dicData: [],
            props: {
              label: "name",
              value: "id",
            },
            rules: [
              {
                required: true,
                message: "请选择商品分组",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择商品分组"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "是否预售",
            prop: "isPreSale",
            type: "select",
            disabled:this.type == 1,
            span: 12,
            row: true,
            dicData: [
              {
                value: 0,
                label: "否",
              },
              {
                value: 1,
                label: "是",
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择是否预售",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择是否预售"));
                  } else {
                    callback();
                  }
                },
              },
            ],
            control: (val, form) => {
              if (val === 1) {
                return {
                  sendDays: {
                    display: true,
                  },
                };
              } else {
                return {
                  sendDays: {
                    display: false,
                  },
                };
              }
            },
          },
          {
            label: "（预售）多少天内发货",
            prop: "sendDays",
            maxlength: 5,
            disabled:this.type == 1,
            showWordLimit: true,
            type: "number",
            span: 12,
            row: true,
            rules: [
              {
                required: true,
                message: "请输入（预售）多少天内发货",
                trigger: "blur",
              },
            ],
          },
          {
            label: "商品主图",
            prop: "mainImg",
            type: "text",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传商品主图",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传商品主图"));
                  } else {
                    callback();
                  }
                },
              },
            ],
            // change: (e) => {
            //   if (e.value !== "") {
            //     this.$refs.form.clearValidate(`mainImg`);
            //   }
            // },
          },
          {
            label: "商品LOGO",
            prop: "logoImg",
            type: "text",
            span: 24,
            rules: [
            
            ],
          },
          {
            label: "商品视频",
            prop: "video",
            type: "upload",
            listType: "picture-img",
            loadText: "上传中，请稍等",
            accept: "video/mp4",
            propsHttp: {
              res: "data",
              url: "path",
              name: "",
            },
            headers: {
              Authorization: this.$store.state.user.Token,
            },
            action: this.videoUrl,
            tip: "建议尺寸：750*750,最大20M",
            fileSize: 20 * 1024,
            span: 24,
            change: (e) => {
              if (e.value !== "") {
                this.$refs.form.clearValidate(`video`);
              }
            },
          },
          {
            label: "banner",
            prop: "bannerImg",
            type: "text",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传banner",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value.length === 0) {
                    callback(new Error("请上传banner"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "商品简介",
            prop: "introduction",
            maxlength: 120,
            span: 12,
            disabled:this.type == 1,
            showWordLimit: true,
            row: true,
            rules: [
              {
                required: false,
                message: "请输入商品简介",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "运费模板",
          //   prop: "templateId",
          //   type: "select",
          //   disabled:false,
          //   span: 12,
          //   row: true,
          //   props: {
          //     label: "name",
          //     value: "id",
          //   },
          //   dicData: [],
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择运费模板",
          //       trigger: "blur",
          //     },
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value === "") {
          //           callback(new Error("请选择运费模板"));
          //         } else {
          //           callback();
          //         }
          //       },
          //     },
          //   ],
          // },
          // {
          //   label: "运费",
          //   prop: "freight",
          //   type: "number",
          //   display: true,
          //   disabled:this.type == 1,
          //   span: 12,
          //   row: true,
          //   maxRows: 9999999,
          //   minRows: 0.01,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入运费",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          {
            label: "商品单位",
            prop: "unit",
            type: "select",
            maxlength: 5,
            showWordLimit: true,
            span: 12,
            disabled:this.type == 1,
            row: true,
            props: {
              label: "name",
              value: "name",
            },
            dicData: [
              {
                value: 0,
                label: "下架",
              },
              {
                value: 1,
                label: "上架",
              },
            ],
            rules: [
              {
                required: true,
                message: "请输入商品单位",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上架状态",
            prop: "status",
            type: "select",
            disabled:this.type == 1,
            span: 12,
            row: true,
            dicData: [
              {
                value: 0,
                label: "下架",
              },
              {
                value: 1,
                label: "上架",
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择上架状态",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择上架状态"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "发货说明",
            prop: "sendIntroduction",
            maxlength: 120,
            span: 12,
            disabled:this.type == 1,
            showWordLimit: true,
            row: true,
          },
          {
            label: "外部商品编码",
            prop: "outerGoodsCode",
            maxlength: 30,
            disabled:this.type == 1,
            showWordLimit: true,
            span: 12,
            row: true,
            rules: [
              {
                required: true,
                message: "请输入外部商品编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "包装清单",
            prop: "packageList",
            maxlength: 120,
            disabled:this.type == 1,
            showWordLimit: true,
            span: 12,
            row: true,
          },
          {
            label: "排序值",
            prop: "sort",
            type: "number",
            disabled:this.type == 1,
            span: 12,
            row: true,
            maxRows: 999999,
            minRows: 1,
            rules: [
              {
                required: true,
                message: "请输入排序值",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  async mounted() {
    let d =
      "https://ahhfms.oss-cn-beijing.aliyuncs.com/headerimages/02964ce9-a2bb-ea7e-5fdc-b7639903c10e.mp4";
    await this.getBrandList();
    await this.listClassify();
    await this.listGrouping();
    await this.getUnit();
    this.$emit("onload");
  },
  methods: {
    //图片上传
    upload(e) {
    console.log(e,'eeeeeeeeee')
      if (Array.isArray(e)) {
        this.form.mainImg = [...e];
      } else if(e !== 'none'){
        this.form.mainImg = [...this.form.mainImg, e];
      }
    },
    //图片上传
    upload1(e) {
      if (Array.isArray(e)) {
        this.form.logoImg = [...e];
      } else {
        this.form.logoImg = [...this.form.logoImg, e];
      }
    },
    //图片上传
    upload2(e) {
      console.log(e, "eeeee");
      if (e.length == 0 || (e.indexOf("png") > -1 || e.indexOf("jpeg") > -1 || e.indexOf("jpg") > -1)  || Array.isArray(e)) {
        if (Array.isArray(e)) {
          this.form.bannerImg = [...e];
        } else {
          this.form.bannerImg = [...this.form.bannerImg, e];
        }
      } else {
        return this.$message.error("请上传图片文件");
      }
    },
    //图片上传
    upload3(e) {
      if (Array.isArray(e)) {
        this.form.video = [...e];
      } else {
        this.form.video = [...this.form.video, e];
      }
    },
    async getBrandList() {
      const { data } = await goodBrandTotalListAll(2);

      const supplierId = this.findObject(this.formOption.column, "brandId");
      supplierId.dicData = data || [];
    },
    async listClassify() {
      const { data } = await listClassify();
      const classifyId = this.findObject(this.formOption.column, "classifyId");
      classifyId.dicData = data || [];
    },
    async getUnit(){
      const { data } = await getGoodsUnitListAll();
      const unit = this.findObject(this.formOption.column, "unit");
      unit.dicData = data || [];
    },
    async listGrouping() {
      const { data } = await list();
      // 筛选不存在2级和三级的数据
      let l = data.filter((item) => {
        item.children =
          item.children &&
          item.children.filter((val) => {
            return val.children;
          });
        return item.children && item.children.length > 0;
      });

      const classifyIds = this.findObject(
        this.formOption.column,
        "classifyIds"
      );
      classifyIds.dicData = l || [];
    },
    setForm(form) {
      console.log('12formformform3',form)
      this.form = form;
      this.formOption.column.map((item) => {
        if (item.prop === "bannerImg") {
          item.propsHttp.name = form.bannerImg;
        }
        if (form.bannerImg.length >= 9) {
          this.upload("none");
        }
      });
    },
    submitForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid, done) => {
          if (valid) {
            done();
            resolve(this.form);
          } else {
            done();
            resolve(false);
          }
        });
      });
    },
    uploadDelete(file, column) {
      // return this.$confirm(`是否确定移除？`, "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   beforeClose: (action, instance, done) => {
      //     // console.log(action)
      //     if (action === "confirm") {
      //       let array = JSON.parse(
      //         JSON.stringify(this.form[column.prop].split(","))
      //       );
      //       let index = array.findIndex((item) => item === file.url);
      //       array.splice(index, 1);
      //       column.propsHttp.name = array.toString();
      //       if (array.length < column.limit) {
      //         this.upload("inline-block", column.prop);
      //       }
      //     }
      //     done();
      //   },
      // });
    },
    uploadError(error, column) {
      error && this.$message.error(error);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      // console.log(column)
      // if (!res.path) {
      //   this.$message.error("上传失败");
      //   loading();
      // } else {
      //   if (column.prop === "banner") {
      //     if (column.propsHttp.name) {
      //       column.propsHttp.name += "," + res.path;
      //     } else {
      //       column.propsHttp.name = res.path;
      //     }
      //   } else {
      //     column.propsHttp.name = res.path;
      //   }
      //   console.log(column.propsHttp.name.split(",").length);
      //   if (column.propsHttp.name.split(",").length >= column.limit) {
      //     console.log(column.propsHttp.name.split(",").length);
      //     console.log(column.limit);
      //     this.upload("none");
      //   }
      // }
    },
    // upload(type) {
    //   let upload = document.getElementsByClassName("el-upload")[2];
    //   upload.style.display = type === "none" ? "none" : "inline-block";
    // },
  },
};
</script>

<style scoped>
/deep/ .avue-form__group .avue-upload__icon {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar {
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
</style>
